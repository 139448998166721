<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white py-2 px-4 rounded-xl absolute -top-11 w-full">
          
          <div class="h-8 relative flex items-center justify-between">
            <div>
              Job list ({{totalJobCount}})
            </div>
          </div>
        </div>
      </div>
      <div v-if="$route.name === 'job'">
        <div>
          <div style="overflow-y: auto; white-space: nowrap;" class="">
             <JobList :showfilters="true" :showcustomer="true" @jobcount="getJobCount" />
          </div>
        </div>
      </div>
     </div>
     <div class="popup_overlay relative px-4" v-if="sendEmailReq">
        <div style="width: 440px;" class="custom_dialog rounded-xl h-min p-4 ">
            <div  class="mb-4 font-semibold">
              <p class="heading-4 text-text1">Export Job Data?</p>
            </div>
            <div  class="mb-5 font-bold">
              <p class="heading-6 text-text1">System Will send excel file in email based on applied filter</p>
            </div>
            <div class="flex gap-2 justify-end">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'No'" @buttonAction="closeBtn"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Send Request'" @buttonAction="sendReqBtn"/>
            </div>
        </div>
      </div>
   </div>
</template>
<script>
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import JobList from './components/JobList.vue'
export default {
  name: "lead",
  components: {
    JobList,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      empStatusList: [],
      searchForJob: '',
      visitLIst: [],
      searchForQuotation: '',
      getJobDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalJobCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        exportData: false,
        sortBy: 'jobNumber',
        sortOrder: 'DESC',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
      showMenuList: false,
      sendEmailReq: false,
    };
  },
  created() {},
  mounted() {
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getVisitList()
      }
      this.addLead = false
    });
     this.getJobDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    // this.getVisitList()
  },
  beforeDestroy() {},
  methods: {
  closeBtn () {
    this.sendEmailReq = false
  },
  sendReqBtn () {
    this.sendEmailReq = false
    this.showMenuList = false
    this.filterObject.exportData = true
    this.getVisitList(true)
  },
  closeList () {
    this.showMenuList = false
  },
    CreateNewjob () {
      this.$router.push({name: 'addNewJob', query: {customerId: this.customerDetailId}})
    },
    showJobDetail (id) {
      this.$router.push({name: 'jobDetail', params: {jobId: id}})
    },
    toggleActiveAndInactiveA (id, dataVal) {
      this.entStatusList.map((data, i) => {
          if (dataVal.id === data.id) {
            if (this.entStatusList[i].selected) {
               this.filterObject.entStatusList = []
               this.entStatusList[i].selected = false
            } else {
                this.filterObject.entStatusList = data.id
               this.entStatusList[i].selected = true
            }
          } else {
            this.entStatusList[i].selected = false
          }
      })
      this.resetPaginationValues = true
      // this.getVisitList()
    },
    searchCall (data) {
      this.searchForJob = data
      this.getJobDebounce()
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true)
    },
    getJobCount (data) {
      this.totalJobCount = data
    },
    getVisitList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getJobList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForJob,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.entStatusList,
        0,
        this.filterObject.exportData,
        '',
        '',
        response => {
          if (this.filterObject.exportData) {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.filterObject.exportData = false
          } else {
            this.totalJobCount = response.Data.count
            let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
            for (let index = 0; index < temp.length; index++) {
              let currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              currentDate = new Date(currentDate);
              const cFormated = moment(currentDate).format('YYYY-MM-DD');
              if (temp[index].isUnschedule) {
                temp[index].dateStatus = 'Unschedule';
              } else {
                if ( moment(new Date(temp[index].nextVisitDateTime)).format('YYYY-MM-DD',) === cFormated) {
                  temp[index].dateStatus = 'Today';
                } else if ( new Date(temp[index].nextVisitDateTime).getTime() < currentDate.getTime()) {
                  temp[index].dateStatus = 'Late';
                } else {
                  temp[index].dateStatus = 'Upcoming';
                }
              }
            }
            this.visitLIst = temp
            this.resetPaginationValues = false
          }
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  }
};
</script>
<style scoped>
</style>