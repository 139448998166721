<template>
    <div v-if="reqDetail !== null" class="mt-4 ">
        <div class="relative mb-4 card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full ">
            <div class="flex  items-center">
                <h1 v-if="reqDetail.requestTitle !== ''" class="heading-1 text-left">{{ reqDetail.requestTitle }}</h1>
                <h1 v-else class="heading-1 text-center">Request for {{ reqDetail.companyName }}</h1>
            </div>
            <div class="absolute right-2 top-2">
                <div class="relative">
                    <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
                        <i class="fas fa-ellipsis-v"></i>
                    </div>
                    <div v-if="showMenuList">
                        <div v-click-outside="closeList">
                            <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-32">
                                <div class="p-2 text-text2 font-medium text-center">
                                    <div class="p-2 cursor-pointer hover:bg-blue-50" v-if="!reqDetail.isArchived && !reqDetail.isConverted" @click="archiveRequest()">
                                        <span class="py-1">Archive</span>
                                    </div>
    
                                    <div class="p-2 cursor-pointer hover:bg-blue-50" @click="editRequest()">
                                        <span class="py-1">Edit</span>
                                    </div>
                                    <div class="p-2 cursor-pointer hover:bg-red-50" @click="deleteReqConfirm = true">
                                        <span class="py-1 text-error">Delete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="xl:grid grid-cols-3 gap-4">
                <div>
                    <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
                        <div class="relative">
                            <div class="flex justify-between">
                                <div class="">
                                    <p>Request #{{ reqDetail.requestId }} </p>
                                </div>
                                <div class=" text-right">
                                    <div class="flex items-center">
                                        <div>
                                            <Chip :textColor="reqDetail.statusColor" :borderColor="reqDetail.statusColor" :font="'bold'" :class="`bg-white py-1 cursor-pointer`" :chipText="reqDetail.statusName" />
                                        </div>
                                        <div class="mb-1">
                                            <span @click="changeStatus" class="pl-2 cursor-pointer text-primary"><i
                              class="fa-solid fa-pen-to-square"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <div class="">
                                    <span class="cursor-pointer text-primary font-semibold" @click.stop="customerDetail()">{{
                        reqDetail.companyName }} ({{ reqDetail.isLead ? 'Lead' : 'Customer'
                        }})</span>
                                </div>
                                <div v-if="reqDetail.customerHotNotes !== ''" class=" text-right">
                                    <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes">
                        {{ showHotNOtes ? 'Hide' : 'Show' }} hot notes</span>
                                </div>
                            </div>
                            <!-- <div v-if="showHotNOtes" class="">
                      <span class="cursor-pointer text-secondary">
                        {{reqDetail.customerHotNotes}}
                      </span>
                    </div> -->
                            <p class="mb-2">Created Date: {{ reqDetail.addedDate | dayDateType }}</p>
                            <div v-if="showHotNOtes" class="">
                                <span class="cursor-pointer text-secondary">
                      {{ reqDetail.customerHotNotes }}
                    </span>
                            </div>
                            <div class="relative mb-2" v-if="this.reqDetail.requestLinkHashExpiry !== ''">
                                <p>
                                    <!-- <span class=" cursor-pointer text-primary" @click.stop="showMenuList1 = true">Share Request</span> -->
                                </p>
                                <div v-if="showMenuList1" class="absolute z-10 left-32 top-0 border">
                                    <div v-click-outside="closeList1">
                                        <div class=" bg-white cust_card">
                                            <div class="p-2 text-text2 font-medium text-center">
                                                <div class="p-2 cursor-pointer text-primary hover:bg-gray-50 flex items-center" @click="copyTextNoInput(reqDetail.reqLinkHash)">
                                                    <i class="fas fa-copy"></i>
                                                    <span ref="jobUrl" class="py-1 pl-2">{{ reqDetail.reqLinkHash }}</span>
                                                </div>
                                                <div class="divider"></div>
                                                <div class="p-2 cursor-pointer hover:bg-gray-50" @click="sharebutton(reqDetail.reqLinkHash)">
                                                    <span class="py-1 text-text2"><i
                                  class="fas fa-external-link text-primary heading-5 cursor-pointer"></i><span
                                  class="pl-2">Open URL</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <div v-if="reqDetail.budgetAmount > 0">
                                    <!-- Budget: {{reqDetail.budgetAmount   }} -->
                                    <!-- Budget: {{ addCommaBudget(reqDetail.budgetAmount) }} -->
                                    <div>
    
                                    </div>
                                    <!-- Budget: {{ reqDetail.budgetAmount | budgetAmountSpaceFormaterWithToFix }}  -->
                                    <!-- USA   Budget: {{ reqDetail.budgetAmount | amountSpaceFormaterWithToFix }}  -->
                                    <div v-if="arEG[3] === 'America/Chicago'">
    
                                        Budget: {{ reqDetail.budgetAmount | amountSpaceUsaFormater }}
    
                                    </div>
                                    <div v-else>
                                        Budget: {{ reqDetail.budgetAmount | budgetAmountSpaceFormaterWithToFix }}
                                        <!-- USA Budget: {{ reqDetail.budgetAmount | amountSpaceFormaterWithToFix }} -->
    
    
                                        <!-- USA Budget: {{ reqDetail.budgetAmount | amountSpaceFormaterWithToFix }}  -->
                                    </div>
    
    
                                </div>
                                <div v-else>
                                    Budget: ?
                                </div>
    
                                <div class="text-error" v-if="reqDetail.decisionBy !== ''">
                                    Decision by : {{ reqDetail.decisionBy | deadline }}
    
                                </div>
                                <div v-else>
                                    Decision by: ?
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- <div v-if="reqDetail.latitude !== 0 && reqDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
                <div class="flex justify-between items-center mb-2">
                  <p class="font-bold">Address </p>
                </div> -->
    
                    <div v-if="reqDetail.latitude !== 0 && reqDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
                        <div class="flex justify-between items-center mb-2">
                            <p class="font-bold">Address</p>
    
                        </div>
                        <div class="">
    
                            <div class="flex whitespace-nowrap justify-between">
                                <div class="flex items-start">
    
                                    <div>
                                        <span @click="openMap(reqDetail.latitude, reqDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span
                            class="text-gray2">:</span></span>
                                    </div>
                                    <div class="pl-1 relative">
    
                                        <p v-if="reqDetail.fullAddress !== ''" class="pr-4   font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="showJobDetail = !showJobDetail">
                                            <span class=" border border-gray2 rounded-md whitespace-nowrap heading-7 px-1 py-0.5 mr-1">
                            {{ reqDetail.addressTitle === '' ? 'Other' : reqDetail.addressTitle }}
                            <!-- {{ addressTitle }} -->
                          </span> {{ reqDetail.fullAddress }}
                                        </p>
                                        <p v-if="reqDetail.fullAddress === ''" class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="showJobDetail = !showJobDetail">
                                            {{ reqDetail.addressLine1 }}
                                        </p>
                                        <div v-if="showJobDetail">
                                            <p class="text-text1 heading-6">
                                                <span v-if="reqDetail.addressTitle !== ''"> {{ reqDetail.addressTitle }}</span>
    
                                                <span v-if="reqDetail.houseNo !== ''">#: {{ reqDetail.houseNo }}</span>
                                                <span v-if="reqDetail.floorNumber !== ''"> (Floor : {{ reqDetail.floorNumber }})</span>
                                            </p>
                                            <p v-if="reqDetail.buildingName !== ''" class="text-text1 ">Bldg: {{ reqDetail.buildingName }}</p>
                                            <p v-if="reqDetail.directionNote !== ''" class="text-text1 whitespace-pre-wrap">Directions: {{ reqDetail.directionNote }}</p>
    
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-start">
                                    <span @click="updateRequestAddress()" class="text-primary cursor-pointer"><i
                          class="fa-solid fa-pen-to-square"></i></span>
                                    <div class="ml-1" v-if="reqDetail.houseNo !== '' || reqDetail.floorNumber !== '' || jobDetails.buildingName !== '' || jobDetails.directionNote !== ''">
                                        <span @click="showJobDetail = !showJobDetail" v-if="showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                                        <p @click="showJobDetail = !showJobDetail" v-if="!showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
    
    
    
                        </div>
                    </div>
                    <div v-if="reqDetail.latitude === 0 && reqDetail.longitude === 0" class="card bg-white rounded-xl p-4 mb-4">
                        <div class="flex justify-between items-center mb-2">
                            <p class="font-bold">Address</p>
                        </div>
                        <div class="">
                            <div class="flex whitespace-nowrap items-start">
                                <div>
                                    <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span
                          class="text-gray2"></span></span>
                                </div>
                                <div class="pl-1">
                                    <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">At Office</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-xl bg-white mb-4 p-4 ">
                        <div class=" relative">
                            <p class="text-text1 font-bold mb-2 heading-4 ">Assign to:</p>
                            <div v-if="reqDetail.assignToList.length > 0">
                                <span class="text-gray3"> </span>
                                <span v-for="(user, index) in reqDetail.assignToList" :key="index" class="chip-assign-member">
                      <span v-if="logedInUser === user.userDetailId">Me</span>
                                <span v-else>{{ user.firstName }} {{ user.lastName }}</span>
                                <span v-if="index < reqDetail.assignToList.length - 1"></span>
                                </span>
                                <!-- <MultiSelect 
            :inputext="employeeSearchText" 
            :inputId="'empslectinpuid'"
            :lableText="''"
            :placholderText="`Select Employee1111`"
            :autoFocus="false"
            :showArrow="true"
            :setReadOnly="false"
            :searchCallApi="false"
            :showPlus="false"
            :keyName="'fullName'"
            :idKey="'userDetailId'"
            :sectionId="'employeelistsecid'"
            :listId="'employeelistidb'"
            :items="employeeList"
            :selectedValues="selectedEmployee"
            @selectNewForVal="selectEmployee"
          /> -->
                            </div>
                            <span @click="addUpdateshedule = !addUpdateshedule" class=" cursor-pointer absolute text-primary top-0 right-0"><i
                      class="fa-solid fa-pen-to-square"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-span-2">
                    <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
                        <div class=" relative">
    
                            <div class="font-bold mb-2">
                                Requirement Details:
                            </div>
    
                            <p v-if="reqDetail.serviceDetail === ''" class="heading-7">No Requirement Details Added</p>
                            <!-- <p class="text-gray4 whitespace-pre-line">{{reqDetail.serviceDetail}}</p> -->
                            <span class="mb-0 text-gray4 whitespace-pre-line">
                    {{ reqDetail.serviceDetail === '' ? '-' : (reqDetail.serviceDetail.length > 700) ? readMoreNote ?
                      reqDetail.serviceDetail : reqDetail.serviceDetail.substr(0, 700) : reqDetail.serviceDetail }}
                    <span v-if="reqDetail.serviceDetail.length > 700" class="text-primary cursor-pointer heading-7"
                      @click="readMoreNote = !readMoreNote">
                      {{ readMoreNote === false ? 'Read More...' : 'Read Less...' }}</span>
                            </span>
                            <span @click="geReqDetail()" class="pl-2 cursor-pointer text-primary absolute top-0 right-7"><i
                      class="fa-solid fa-refresh"></i></span>
                            <span @click="updateJob('notes', reqDetail.serviceDetail, reqDetail.serviceAttachmentDetail.attachmentList)" class="pl-2 cursor-pointer text-primary absolute top-0 right-0"><i
                      class="fa-solid fa-pen-to-square"></i></span>
                        </div>
                        <div v-if="reqDetail.serviceAttachmentDetail.attachmentList !== null" class="mt-2">
                            <!-- <p class="text-primary heading-6 mb-2">Attachments:</p> -->
                            <div class="flex gap-4" style="flex-wrap: wrap;">
                                <div class=" card rounded-sm border-t border-gray-100 bg-white attachment-card cursor-pointer group relative" style="width: 110px; height: 76px" v-for="(attach, a) in reqDetail.serviceAttachmentDetail.attachmentList " :key="a" @click="showFullImage(attach)">
                                    <div v-if="tekeType(attach.fileType) === 'image'" class="text-center flex justify-center">
                                        <img style="height: 60px;width: auto;" :src="`${reqDetail.serviceAttachmentDetail.pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i>
                                        </p>
                                    </div>
                                    <div v-if="tekeType(attach.fileType) === 'video'" class="flex justify-center items-center w-full">
                                        <img style="height: 60px;width: auto;" :src="`${reqDetail.serviceAttachmentDetail.pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                                        <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i>
                                        </p>
                                    </div>
                                    <div v-if="tekeType(attach.fileType) === 'pdf'" class="flex justify-center items-center w-full">
                                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i>
                                        </div>
                                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i>
                                        </p>
                                    </div>
                                    <div v-if="tekeType(attach.fileType) !== 'image' && tekeType(attach.fileType) !== 'video' && tekeType(attach.fileType) !== 'pdf'" class="flex justify-center items-center w-full">
                                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i>
                                        </div>
                                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i>
                                        </p>
                                    </div>
                                    <div class="text-text2 text-center heading-8">{{ attach.uploadedOn | dateFilter1 }}</div>
                                </div>
                            </div>
                            <div class="card rounded-xl bg-white mb-4 p-4">
                                <Tabs :tabsList="Quote" />
                                <div v-if="selectedName === 'Quote'">
                                    <div class="flex justify-end">
                                        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create New Quote'" @buttonAction="convertToQuote" style="padding-top: 15px;" />
                                    </div>
                                    <ReqQuotelist v-if="overView.quotesList !== null" :quotesList="overView.quotesList" />
                                    <div v-else>
                                        <NoRecords :alertMessage="`No records found.`" />
                                    </div>
    
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="card rounded-xl bg-white mb-4 p-4">
                        <div>
                            <div class="mb-4">
                                <Tabs :tabsList="tabTitle" />
                            </div>
                            <!-- {{ totalQuoteCount }}  -->
                            <li v-for="(tabTitle, index) in tabTitles" :key="index">{{ taskCount }}</li>
    
                            <div v-if="selectedName === 'Quote'">
    
                                <div class="flex justify-end">
                                    <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create New Quote'" @buttonAction="convertToQuote" />
                                </div>
    
                                <ReqQuotelist v-if="overView.quotesList !== null" :quotesList="overView.quotesList" />
                                <div v-else>
                                    <NoRecords :alertMessage="`No records found.`" />
                                </div>
    
                            </div>
                            <div v-if="selectedName === 'Job'">
                                <!-- {{totalCount}} -->
    
                                <div class="flex justify-end">
                                    <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create New Job'" @buttonAction="convertToJob" />
                                </div>
    
    
    
                                <div class="flex justify-end">
                                    <!-- <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'chekCount'"
                        @buttonAction="chekCount" /> -->
                                </div>
                                <ReqJobLIst v-if="overView.jobsList !== null" :list="overView.jobsList" />
                                <div v-else>
                                    <NoRecords :alertMessage="`No records found.`" />
                                </div>
    
                            </div>
    
                            <div v-if="selectedName === 'Task'">
                                <!-- {{ totalCount  }} -->
                                <!-- {{ taskCount }} -->
                                <!-- <li v-for="(tabTitle, index) in tabTitles" :key="index">{{ taskCount }}</li> -->
                                <div v-for="item in list" v-bind:key="item.Data">
                                    {{ item.Data.count }}
                                </div>
                                <!-- {{ tabTitles }} -->
                                <div class="flex justify-end">
    
                                    <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create New Task'" @buttonAction="createTask" />
                                </div>
                                <ReqTaskList v-if="overView.taskList !== null" :list="overView.taskList" />
                                <div v-else>
                                    <NoRecords :alertMessage="`No records found.`" />
                                </div>
    
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="reqDetail !== null" class="card rounded-xl bg-white mb-4 p-4">
                  <NotesList :detailId="parseInt(this.$route.params.requestId)" :requestType="'request'" :noteTitle="'Request Note'" />
                </div> -->
                </div>
            </div>
        </div>
        <div v-if="changeStatusDialog" class="popup_overlay relative px-4">
            <div style="width: 400px;" class="custom_dialog rounded-xl h-min">
                <div class="bg-primary flex justify-between p-2">
                    <div class=" font-medium text-white heading-3">
                        Change Status
                    </div>
                    <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-0.5 rounded-xl" @click.stop="changeStatusDialog = false">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <div class="p-1 text-center font-bold heading-4 m-2" v-for="(data, index) in statusList" :key="index" :class="reqDetail.statusName === data.statusName ? 'bg-blue-50' : 'hover:bg-gray-100'">
                    <div class="cursor-pointer" @click="selectStatus(data)" :style="`color: ${data.statusColor};`">
                        {{ data.statusName }} </div>
                </div>
            </div>
        </div>
        <UpdateInsruction v-if="updateInstruction" :pathPrefix="reqDetail.serviceAttachmentDetail.pathPrefix" :note="updateValue" :attachment="insArray" :reqType="reqType" :bAmount="reqDetail.budgetAmount" :decisiondate="reqDetail.decisionBy" />
        <FullImage :fileDate="fileDate" v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" :isFromContactId="isFromContactId" />
        <AddUpdateShedule :requetsObj="reqDetail" :requestId="reqDetail.requestId" :selectedTeam="selectedTeam" :assementObj="assementObj" :addUpdateshedule="true" v-if="addUpdateshedule" />
        <ConfirmBox v-if="deleteReqConfirm" :message="'Want to delete this request'" :title="'Are you sure?'" />
        <ReqAddressAddUpdate :addressList="addressList" :AddressObj="reqDetail" v-if="editReAddress" />
        <CreateFollowupTask v-if="isCreateNewTask" :reqDetail="reqDetail" />
    </div>
</template>

<script>
var moment = require('moment')
import UpdateInsruction from '@/View/job/components/updateServiceName.vue';
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
// import NotesList from '@/View/job/visit/NotesList.vue';
import AddUpdateShedule from '@/View/request/components/AddUpdateShedule.vue';
import { FilterPermissions } from '@/utils/Permissions.js'
import FullImage from '../endUserNewFlow/components/FullImage.vue';
import ReqAddressAddUpdate from './components/ReqAddressAddUpdate.vue';
import milesCountry from "@/assets/milesCountry.json";
import timeZoneWithCountryCode from "@/assets/timeZoneWithCountryCode.json";
import Tabs from '../components/tabs.vue';
import ReqQuotelist from './components/reqQuotelist.vue';
import ReqJobLIst from './components/reqJobList.vue';
import ReqTaskList from './components/reqTaskList.vue';
import NoRecords from '@/View/components/noRecords.vue'
import Chip from '../components/chip.vue';
import CreateFollowupTask from './components/createFollowupTask.vue';
export default {
    name: "lead",
    components: {
        UpdateInsruction,
        FullImage,
        ConfirmBox,
        NoRecords,
        Button,
        AddUpdateShedule,
        ReqAddressAddUpdate,
        Tabs,
        ReqQuotelist,
        ReqJobLIst,
        ReqTaskList,
        Chip,
        CreateFollowupTask,
    },
    data() {
        return {
            selectedName: 'Quote',
            activeIndex: 0,
            tabTitle: [
                { name: 'Quote', active: true, canViewPermission: true },
                { name: 'Job', active: false, canViewPermission: true },
                { name: 'Task', active: false, canViewPermission: true },
            ],
            Quote: [
                { name: 'Quote', active: false, canViewPermission: true },

            ],
            arEG: [],
            isCreateNewTask: false,
            employeeSearchText: "",
            employeeList: [],
            selectedEmployee: [],
            LoginUserlatLong: null,
            OfficelatLong: null,
            changeStatusDialog: false,
            editReAddress: false,
            addressList: [],
            statusList: [],
            showHotNOtes: false,
            fileDate: '',
            isFromContactId: 0,
            readMoreNote: false,
            showJobDetail: false,
            isCard: true,
            updateInstruction: false,
            fileName: '',
            fileType: '',
            fileVaultId: '',
            totalQuoteCount: 0,
            shoFullImage: false,
            canQuotePermission: FilterPermissions('quote'),
            canJobPermission: FilterPermissions('job'),
            isCustomerPermission: FilterPermissions('customer'),
            isLeadPermission: FilterPermissions('lead'),
            orgDetail: null,
            showMenuList1: false,
            showMenuList: false,
            overView: null,
            reqDetail: null,
            anyTime: false,
            addUpdateshedule: false,
            morning: false,
            totalCount: 0,
            afternoon: false,
            evening: false,
            insArray: [],
            deleteReqConfirm: false,
            allDay: false,
            selectedTeam: [],
            logedInUser: 0,
            timeZone: '',
            assementObj: {
                instructions: '',
                isUnschedule: true,
                isAnyTime: false,
                startDateTime: '',
                endDateTime: '',
                isSchedule: false,
            },
        };
    },
    created() {
        window.addEventListener("resize", this.resizeWindowHandler);
    },
    mounted() {
        // let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
        this.arEG = new Intl.Locale("en-US").getTimeZones();
        this.LoginUserlatLong = JSON.parse(
            localStorage.getItem("LoginUserlatLong")
        );
        this.OfficelatLong = JSON.parse(localStorage.getItem("orgInfo"));
        this.getReqStatusList()
        this.resizeWindowHandler()
        this.$root.$on('updateJobHandler', (data) => {
                if (data) {
                    this.geReqDetail()
                }
                this.updateInstruction = false
            }),

            (response) => {

                this.totalCount = response.Data.count

            }


        // taskCount() {
        //   return this.tabTitle.length
        // }

        setTimeout(() => {
            let temp = FilterPermissions('request')
            if (temp) {
                let detailObj = JSON.parse(localStorage.getItem('jobUserInfo'))
                this.logedInUser = detailObj.userDetailId
                this.geReqDetail(true)
                this.geoverviewReq()
            } else {
                this.$store.dispatch('ShowSubPopup', { status: true, title: `You don't have permission to show Request detail` })
            }
        }, 200);
        this.$root.$on('confirmBoxHandler', (data) => {
            if (data && this.deleteReqConfirm) {
                this.deleteReq()
            }
            document.body.style = 'overflow: visible;'
            this.deleteReqConfirm = false
        })
        this.$root.$on('fullImageHandlerEnd', () => {
            this.shoFullImage = false
        })
        this.$root.$on('closeScheduleHandler', (response) => {
            if (response) {
                this.geReqDetail()
            }
            this.addUpdateshedule = false
        })
        this.$root.$on("ReqaddreHandler", (response, data) => {
            if (response && data && this.editReAddress) {
                let obj = {
                    requestId: this.reqDetail.requestId,
                    addressLine1: data.addressLine1,
                    addressLine2: data.addressLine2,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                    country: data.country,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    geofenceRangeDiameter: data.geofenceRangeDiameter,
                    fullAddress: data.fullAddress,
                    houseNo: data.houseNo,
                    floorNumber: data.floorNumber,
                    buildingName: data.buildingName,
                    directionNote: data.directionNote,
                    contactNumber: data.contactNumber,
                    contactName: data.contactName,
                    addressTitle: data.addressTitle,
                }
                this.updateRqAddress(obj)
                // console.log("obj-->",obj); 

            } else {
                this.editReAddress = false
            }
        });
        this.$root.$on('setNewTabActive', (index) => {
            this.tabsSet(index)
        })
        this.$root.$on('followupTaskHandler', () => {
            this.isCreateNewTask = false
        })
        // const arEG = new Intl.Locale("en-US").getTimeZones();

    },
    beforeDestroy() {
        this.$root.$off("ReqaddreHandler");
        this.$root.$off("confirmBoxHandler");
        this.$root.$off("updateJobHandler");
        this.$root.$off("setNewTabActive");
    },
    methods: {
        tabsSet(index) {
            console.log('checl index', index);

            this.tabTitle.forEach((element, i) => {
                if (index === i) {
                    element.active = true
                    this.selectedName = element.name
                } else {
                    element.active = false
                }
            });
        },
        //  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,



        taskCount1() {
            const arEG = new Intl.Locale("en-US").getTimeZones();
            console.log("Usa Time zone chek", arEG[3]);

        },
        taskCount(index) {
            this.activeIndex = index
        },
        getReqStatusList() {
            MyJobApp.getReqStatusList(
                response => {
                    this.statusList = response.Data
                    this.$store.dispatch('SetLoader', { status: false, message: '' })
                },
                () => {
                    this.resetPaginationValues = false
                    this.$store.dispatch('SetLoader', { status: false, message: '' })
                }
            )
        },
        selectStatus(data) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading..." });
            this.changeStatusDialog = false
            MyJobApp.UpdateRequestStatus(
                this.reqDetail.requestId,
                data.requestStatusId,
                (response) => {
                    this.geReqDetail()
                    this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'success' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },
        chekOverViewQuotesList() {

            console.log("chekOverViewQuotesList----->", this.xyz);

        },
        changeStatus() {
            this.changeStatusDialog = true
        },
        updateRequestAddress() {
            this.editReAddress = true
        },
        resizeWindowHandler() {
            this.screenWidth = window.innerWidth
            if (window.innerWidth <= 640) {
                this.isCard = false
            } else {
                this.isCard = true
            }
        },
        updateJob(type, value, data) {
            this.reqType = type
            this.updateValue = value
            if (data !== null) {
                this.insArray = this.attachmentArray
            }
            this.updateInstruction = true
        },
        tekeType(fileType) {
            let temp = fileType.split('/')
            let type = ''
            if (temp[0] === 'image') {
                type = 'image'
            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                type = 'pdf'
            } else if (temp[0] === 'video') {
                type = 'video'
            }
            return type
        },
        copyTextNoInput() {
            this.selectText(this.$refs.jobUrl)
            document.execCommand('copy')
            this.$store.dispatch('SetAlert', { showAlert: true, message: 'Job url copied', color: 'success' })
        },
        selectText(element) {
            var range
            if (document.selection) {
                range = document.body.createTextRange()
                range.moveToElementText(element)
                range.select()
            } else if (window.getSelection) {
                range = document.createRange()
                range.selectNode(element)
                window.getSelection().removeAllRanges()
                window.getSelection().addRange(range)
            }
        },


        sharebutton(data) {
            // // let url = this.$router.resolve({name: 'jd', params: {infoId: 0}})
            // let url = this.$router.resolve({name: 'jd', params: {infoId: 0}})
            // window.open(url.href, '_blank')
            window.open(data, '_blank')
        },
        updateRqAddress(data) {
            this.$store.dispatch('SetLoader', { status: true, message: 'Generating...' })
            MyJobApp.addUpdateReqAddress(
                data,
                () => {
                    this.geReqDetail()
                    this.editReAddress = false;
                    this.$store.dispatch('SetLoader', { status: false, message: '' })
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch('SetLoader', { status: false, message: '' })
                }
            )
        },
        GenerateJob() {
            this.$store.dispatch('SetLoader', { status: true, message: 'Generating...' })
            MyJobApp.GenerateRequestShareLink(
                this.reqDetail.requestId,
                () => {
                    this.geReqDetail()
                    this.$store.dispatch('SetLoader', { status: false, message: '' })
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch('SetLoader', { status: false, message: '' })
                }
            )
        },
        selectEmployee(emplist) {
            this.selectedEmployee = []
            let tempEmpList = []
            emplist.map((data) => {
                let obj = {
                    userDetailId: data.userDetailId,
                    fullName: data.fullName
                }
                tempEmpList.push({ userDetailId: data.userDetailId })
                this.selectedEmployee.push(obj)
            })
            setTimeout(() => {
                this.$root.$emit('setSelectedEmpHandler', tempEmpList)
            }, 200);
        },

        closeList() {
            this.showMenuList = false
        },
        closeList1() {
            this.showMenuList1 = false
        },
        convertToJob() {
            if (this.canJobPermission) {
                this.$router.push({ name: 'addNewJob', query: { customerId: this.reqDetail.customerId, requestType: 'request', detailId: parseInt(this.$route.params.requestId) } })
            } else {
                this.$store.dispatch('ShowSubPopup', { status: true, title: `You don't have permission to Convert Job` })
            }
        },
        createTask() {
            this.isCreateNewTask = true

        },

        convertToQuote() {
            if (this.canQuotePermission) {
                this.$router.push({ name: 'EditQuote', params: { quoteId: 0 }, query: { customerId: this.reqDetail.customerId, requestType: 'request', detailId: parseInt(this.$route.params.requestId) } })
            } else {
                this.$store.dispatch('ShowSubPopup', { status: true, title: `You don't have permission to Convert Quote` })
            }
            // console.log("query: { customerId: this.reqDetail.customerId , requestType: 'request', detailId: parseInt(this.$route.params.requestId)---->");

        },
        customerDetail() {
            if (this.isCustomerPermission || this.isLeadPermission) {
                this.$router.push({ name: 'customerDetails', params: { customerId: this.reqDetail.customerId } })
            } else {
                this.$store.dispatch('ShowSubPopup', { status: true, title: `You don't have permission to open Customer Detail` })
            }
        },
        deleteReq() {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
            MyJobApp.deleteRequest(
                parseInt(this.$route.params.requestId),
                (response) => {
                    this.$router.push({ name: 'request' }).catch(() => {});
                    this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },
        AddUpdateSheduleBtn() {
            this.addUpdateshedule = true
        },
        showFullImage(data) {
            this.fileVaultId = data.fileVaultId
            this.fileType = this.tekeType(data.fileType)
            this.fileName = data.displayFileName
            this.isFromContactId = data.addedById
            this.fileDate = data.uploadedOn
            this.shoFullImage = true
        },
        completeAsmnt(data) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
            MyJobApp.CompleteAssessment(
                parseInt(this.$route.params.requestId),
                data,
                (response) => {
                    this.geReqDetail()
                    this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },
        editRequest() {
            this.$router.push({ name: 'editRequest', params: { requestId: parseInt(this.$route.params.requestId) } })
        },
        chekCount() {
            console.log("Chek Count of requste page", this.totalCount);
            console.console.log("----isCreateNewTask-----", this.isCreateNewTask, "------------");

        },
        archiveRequest() {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
            MyJobApp.RequestArchive(
                parseInt(this.$route.params.requestId),
                (response) => {
                    this.geReqDetail()
                    this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },
        // Compuetd: [
        // countTask(){
        //   return this.tabTitle.filter(item=>(item.name==="Task")).length
        // }
        // ]

        geoverviewReq() {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
            MyJobApp.overviewReq(
                parseInt(this.$route.params.requestId),
                (response) => {
                    this.overView = response.Data

                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },

        geReqDetail(getCust) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
            MyJobApp.getRequestDetail(
                parseInt(this.$route.params.requestId),
                (response) => {
                    if (getCust) {
                        this.getCustDetails(response.Data.customerId)
                    }
                    this.reqDetail = response.Data;
                    let temp = response.Data.arrivalTime.split(",")
                    this.selectedTeam = response.Data.assignToList
                    this.assementObj.instructions = response.Data.instructions
                    this.assementObj.isUnschedule = response.Data.isUnschedule

                    this.assementObj.startDateTime = response.Data.startTime
                    this.assementObj.endDateTime = response.Data.endTime
                    this.assementObj.isSchedule = true
                    temp.map(text => {
                        if (text === 'morning') {
                            this.morning = true
                        }
                        if (text === 'anyTime') {
                            this.anyTime = true
                        }
                        if (text === 'afternoon') {
                            this.afternoon = true
                        }
                        if (text === 'evening') {
                            this.evening = true
                        }
                    })
                    if (this.reqDetail.serviceAttachmentDetail.attachmentList !== null) {
                        this.attachmentArray = []
                        this.reqDetail.serviceAttachmentDetail.attachmentList.map(a => {
                            let temp = a.fileType.split('/')
                            let type = ''
                            if (temp[0] === 'image') {
                                type = 'image'
                            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                                type = 'pdf'
                            } else if (temp[0] === 'video') {
                                type = 'video'
                            }
                            this.attachmentArray.push({ ...a, docType: type })
                        })
                    }
                    var startTime1 = moment(new Date());
                    var endTime2 = moment(new Date(this.reqDetail.requestLinkHashExpiry));

                    if (endTime2.isBefore(startTime1)) {
                        this.reqDetail.reqLinkHash = '';
                        this.reqDetail.requestLinkHashExpiry = '';
                    }
                    if (this.reqDetail.latitude === 0 && this.reqDetail.longitude === 0) {
                        this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
                        MyJobApp.getOrgDetail(
                            (response) => {
                                this.orgDetail = response.Data
                                this.$store.dispatch("SetLoader", { status: false, message: "" });
                            },
                            (err) => {
                                this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                                this.$store.dispatch("SetLoader", { status: false, message: "" });
                            }
                        );
                    }
                    if (response.Data.startTime !== "" && response.Data.endTime) {
                        var startTime = moment(response.Data.startTime, )
                        var endTime = moment(response.Data.endTime, )
                        var hoursDiff = endTime.diff(startTime, 'hours')
                        var minutesDiff = endTime.diff(startTime, 'minutes') % 60
                        if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
                            this.allDay = true
                            this.assementObj.isAnyTime = true
                        } else {
                            this.allDay = false
                        }
                    }
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                },
                (err) => {
                    this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },

        getCustDetails(custId) {
            this.$store.dispatch("SetLoader", {
                status: true,
                message: "Loading...",
            });
            MyJobApp.getCustomerDetail(
                custId,
                (response) => {
                    this.addressList = [];
                    if (response.Data.addressList !== null) {
                        response.Data.addressList.forEach((e) => {
                            let temp = 0;
                            let temp3 = "";
                            if (this.LoginUserlatLong !== null) {
                                temp = this.calcDistance(
                                    this.LoginUserlatLong.latitude,
                                    this.LoginUserlatLong.longitude,
                                    e.latitude,
                                    e.longitude
                                );
                                temp3 = ` from user`;
                            } else {
                                temp = this.calcDistance(
                                    this.OfficelatLong.latitude,
                                    this.OfficelatLong.longitude,
                                    e.latitude,
                                    e.longitude
                                );
                                temp3 = ` from office`;
                            }

                            let value = this.getDistanceUnit(temp * 1000);

                            this.addressList.push({
                                ...e,
                                selected: false,
                                displayDistance: value + temp3,
                                addressText: `(${e.addressTitle === "" ? "Other" : e.addressTitle}) -` +
                                    ` ${e.fullAddress}`,
                            });
                        });
                    }
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                },
                () => {
                    this.$store.dispatch("SetLoader", { status: false, message: "" });
                }
            );
        },
        addCommaBudget(val) {
            // return val.toString().replace((/(\d)(?=(\d{2})+(?!\d))/g), ",").replace((/(\d+)(\d{3})/), "$1,")
            // const amount = val.toString()
            // const lastNumber = amount.slice(-3)
            // const otherNumber = lastNumber.slice(0, -3)
            return val.toString().replace((/(\d)(?=(\d{2})+(?!\d))/g), ",").replace((/(\d+)(\d{3})/), "$1,")
        },
        openMap(lat, lng) {
            let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
            window.open(url, '_blank')
        },
        getDistanceUnit(distanceMeter) {
            let distance = "";
            let countryCode;
            const moment = require("moment-timezone");
            const countryTimeZone = moment.tz.guess(); // Guesses the user's time zone
            timeZoneWithCountryCode.map((itm) => {
                const ind = itm.timezones.findIndex((x) => x.name === countryTimeZone);
                if (ind >= 0) {
                    countryCode = itm.code;
                }
            });
            const index = milesCountry.findIndex(
                (x) => x.countryCode === countryCode
            );
            if (index >= 0) {
                if (distanceMeter * 0.00062137 >= 1000) {
                    distance = `999+ mi`;
                } else if (distanceMeter * 0.00062137 < 0.1) {
                    distance = "";
                } else {
                    distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
                }
            } else if (distanceMeter / 1000 >= 1000) {
                distance = `999+ km`;
            } else if (distanceMeter / 1000 < 0.1) {
                distance = "";
            } else {
                distance = `${(distanceMeter / 1000).toFixed(1)} km`;
            }
            return distance;
        },
        calcDistance(lat1, lon1, lat2, lon2) {
            const radius = 6371; // Earth's radius in km
            const dLat = this.toRadians(lat2 - lat1);
            const dLng = this.toRadians(lon2 - lon1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.toRadians(lat1)) *
                Math.cos(this.toRadians(lat2)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = radius * c;
            return distance.toFixed(2);
        },
        toRadians(degree) {
            return degree * (Math.PI / 180);
        },
    },
};
</script>

<style scoped>
.chip {
    display: inline-block;
    background-color: #eaeaea;
    border-radius: 10px;
}

.chip-assign-member {
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #1295BA;
    color: #1295BA;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
}

.attachment-card2 {
    display: flex;
    align-items: center;
    position: relative;
}

.scroll-img-view {
    height: 104px;
}

.zIndex {
    z-index: 100 !important;
}
</style>