<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: visible; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              <span v-if="noteId > 0">Update Note</span>
              <span v-else>Add Note</span>
            </div>
          </div>
      <div class="flex flex-wrap mt-3">
        <div class="flex flex-wrap mt-3 ml-3">Categories: </div>
          <div class="" v-for="(status, index) in notesCategories" :key="index" >
          <StatusBtn class="whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.isDefault" :btnText="status.categoryName" :lastInlist="notesCategories.length === index + 1"/>     
        </div>
      </div>
          <!-- <div>Notes Categories ({{notesCategories.length}})</div> -->
          <div class="p-4">
            <div class=" mb-4">
            <TextAreaInput
                :inputId="'notinuid'"
                :inputext="note"
                :placholderText="`Write note here...`"
                :lableText="''"
                :inputType="'text'"
                :autoFocus="true"
                :textMaxlength="1000"
                :showcharLimit="true"
                :cols="50"
                :rows="3"
                :setReadOnly="false"
                @inputChangeAction="(data) => note = data"  />
            </div>
            <div v-if="$route.name === 'visitDetail' || $route.name === 'jobDetail'" class="flex items-center mb-2">
              <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck()" v-model="visibleToCustomer" >
              <span class="pl-2 text-text2 cursor-pointer" @click="checkboxCheck()">Visible to customer</span>
            </div>
            <div class="flex flex-wrap gap-4 mb-4" v-if="presentAttch.length > 0">
              <div class=" flex items-center relative card rounded-xl border-t border-gray-100 bg-gray1 attachment-card group" style="width: 120px; height: 80px"  v-for="(attach, a) in presentAttch " :key="a">
                <div v-if="attach.docType === 'image'" class="text-center">
                    <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                    <div class="invisible group-hover:visible absolute bg-gray4 w-full min-w-max  card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.fileName }}</div>
                </div>
                <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                  <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                  <img class="flex items-center  text-4xl absolute text-white " height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.fileName }}</div>
                </div>
                <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.fileName }}</div>
                </div>
                <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.fileName }}</div>
                </div>
                <span @click="removeAttach(attach,a)" class=" absolute top-0 cursor-pointer right-0 bg-white rounded-full flex justify-center heading-3 p-0.5 border-primary"><i class="fa-solid fa-circle-xmark"></i></span>
               </div>  
            </div>
            <div>
                <div class="col-span-12">
                <input id="files" type="file" accept="image/*,video/*" ref="file" style="display: none" @change="uploadFiles"/>
                <a
                  @click="$refs.file.click()"
                  class="heading-6 ml-2 text-primary cursor-pointer"
                >
                + Add Attachment
                </a>
              </div>
                <div class=" col-span-12">
                <div>
                  <div class="" v-for="(data, index) in fileName" :key="index" >
                    <p class="text-text1 font-bold" v-if="index === 0">
                      File(s) attachment
                    </p>
                    <div v-if="data.uploadedFile" class=" flex">

                      <div class="flex pr-2">
                        <span class="text-gray3 whitespace-nowrap pr-2">File Name:</span>
                        <div style="display: grid;">
                            <span class="mb-0 mt-0 subtitle-2 text-text2" style="width: 100%; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ data.file.name.split('.')[0]}}</span>
                        </div>
                        <span class="text-text2">.{{data.file.name.split('.')[1]}}</span>
                        </div>

                      <p class="text-text1">
                      
                      <span @click="removeFile(index, data)"><i class="fas fa-minus-circle text-error" style="margin-top:-3px" ></i></span>
                    </p>
                    </div>
                    <div v-else class="progressDiv">
                      <p class="subtitle-1 font-weight-bold overFlowPara">{{ data.file.name }}</p>
                      <p :id="`progress${data.id}`"></p>
                      <div class="w-full" v-if="data.progressLinearValue">
                        <div class="progress-bar">
                          <div class="progress-bar-value"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :disabled="saveDisabled" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveNote()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
import StatusBtn from "@/View/components/statu-filter-btn.vue";

// import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'addUpdatenote',
  components: {
    TextAreaInput,
    Button,
    StatusBtn,
  },
  data () {
    return {
      usedStorageVal: 0,
      ImageCount: 0,
      VideoCount: 0,
      showErr: '',
      ImageSizePermission: FilterPermissions('max_image_upload_size'),
      VideoSizePermission: FilterPermissions('max_video_upload_size'),
      canMultipleImagePermission: FilterPermissions('image_limit'),
      canMultipleVideoPermission: FilterPermissions('video_limit'),
      canPlanAssignStorage: FilterPermissions('max_storage'),
      isMultipleSel: false,
      visibleToCustomer: false,
      note:'',
      noteErr:'',
      saveDisabled: true,
      files: [],
      fileName: [],
      isDragging: false,
      uploadFileValid: false,
      fileErr: false,
      fileUpload: false,
      noteId: 0,
      presentAttch: [],
      deleteAttach: [],
      defaultNotesCategories:[],
      notesCategories: [],
      notesCategoriesObj:{
        selectedCategoryId:'',
        selectedCategoryName:'',
        isDefault:''
      }
    }
  },
  props: ['reqestId', 'requessType', 'pathPrefix', 'updateNoteObj'],
   created() {
  },
  mounted () {
    console.log('inside mounted updateNoteObj',this.updateNoteObj);
    
    this.getStorageInfo()
    this.getNoteCategoryList()
    if (this.updateNoteObj !== null) {
      this.note = this.updateNoteObj.notes
      this.noteId = this.updateNoteObj.noteId
      this.presentAttch = this.updateNoteObj.attachmentList
      this.visibleToCustomer = this.updateNoteObj.visibleToCustomer
      
    }
    document.body.style = 'overflow: hidden;'
  },
  watch: {
    ImageCount: {
      handler () {
        console.log('IMAGE', this.ImageCount)
      },
      deep: true
    },
    VideoCount: {
      handler () {
        console.log('VIDEO', this.VideoCount)
      },
      deep: true
    },
    visibleToCustomer: {
      handler () {
        if (this.note.trim() !== '') {
            this.saveDisabled = false
        } else if (this.files.length === 0) {
            this.saveDisabled = true
        } else {
            this.saveDisabled = false
        }
        if (this.noteId > 0) {
          this.saveDisabled = false
        }
      },
      deep: true
    },
    note: {
      handler () {
        if (this.note.trim() !== '' ||  this.notesCategoriesObj.isDefault === true) {
            this.saveDisabled = false
        } else if (this.files.length === 0) {
            this.saveDisabled = true
        } else {
            this.saveDisabled = false
        }
      },
      deep: true
    },
    notesCategoriesObj: {
      handler () {
        if (this.notesCategoriesObj.isDefault === true) {
          if(this.note.trim() !== ''){
             this.saveDisabled = false
          }else{
            this.saveDisabled = true
          }
        } else {
            this.saveDisabled = true
        }
      },
      deep: true
    },
    presentAttch: {
      handler () {
        if (this.presentAttch.length === 0) {
          this.saveDisabled = true
        } else {
          this.saveDisabled = false
        }
      },
      deep: true
    },
    fileName: {
      handler () {
        if (this.fileName.length === 0) {
          this.saveDisabled = true
        } else if (this.note.trim() !== '') {
          this.saveDisabled = false
        } else {
          this.saveDisabled = false
        }
      },
      deep: true
    }
  },
  methods: {
    getNoteCategoryList () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetNotesCategories(
          response => {
            console.log("getNoteCategoryList",response.Data.categoryList);
            
            this.notesCategories = response.Data.categoryList !== null ? response.Data.categoryList : []
            this.defaultNotesCategories = this.notesCategories.find(category => category.isDefault)
            console.log('getNoteCategoryList defaultNotesCategories',this.defaultNotesCategories);

            if(this.defaultNotesCategories){
              this.notesCategoriesObj.selectedCategoryId=this.defaultNotesCategories.noteMasterCategoryId
              this.notesCategoriesObj.isDefault=true
            }

            if(this.updateNoteObj !== null){
              this.notesCategories.map((data,i) => {
                 if (data.noteMasterCategoryId==this.updateNoteObj.categoryId) {
            console.log("inside if getNoteCategoryList updateNoteObj",this.notesCategories);
              this.notesCategories[i].isDefault=true    
              this.notesCategoriesObj.isDefault=true

          } else{
              this.notesCategories[i].isDefault=false
          }
      })
            }

            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    
    toggleActiveAndInactiveA (id, dataVal) {      
        this.notesCategories.map((data,i) => {
          if (dataVal.noteMasterCategoryId === data.noteMasterCategoryId) {
              // console.log('inside if data id ',dataVal.noteMasterCategoryId,dataVal.categoryName);
              this.notesCategoriesObj.selectedCategoryId=dataVal.noteMasterCategoryId
              this.notesCategoriesObj.selectedCategoryName=dataVal.categoryName
              this.notesCategoriesObj.isDefault=true
              this.notesCategories[i].isDefault=true          
          } else{
              this.notesCategories[i].isDefault=false
          }
      })
      
    },
    checkboxCheck () {
      this.visibleToCustomer = !this.visibleToCustomer
    },
    removeAttach (data, index) {
      let obj = {
          fileName: data.fileName,
          fileType: data.fileType,
          thumbPath: data.thumbPath,
          notesAttachmentMappingId: data.notesAttachmentMappingId,
          fileVaultId: data.fileVaultId,
        }
        this.deleteAttach.push(obj)
        this.presentAttch.splice(index, 1)
    },
    removeFile (index, data) {
      this.showErr = ''
        if (data.file.type === 'image/png' || data.file.type === 'image/jpg' || data.file.type === 'image/jpeg' || data.file.type === 'image/gif' || data.file.type === 'image/svg' || data.file.type === 'application/pdf') {
          this.ImageCount --
        } else if (data.file.type === 'video/mp4') {
          this.VideoCount --
        }
        this.fileName.splice(index, 1)
        const fileListArr = Array.from(this.files)
        fileListArr.splice(index, 1)
        this.files = fileListArr
        console.log('datadatadata', data.file)
        console.log(this.files)
    },
    async uploadFiles (event) {
      if (this.canPlanAssignStorage) {
        let mb = parseInt(this.canPlanAssignStorage.v)*1024
        console.log('usedStorageVal', this.usedStorageVal)
        console.log('usedStorageVal mb', mb)
        this.showErr = ''
        if (mb > this.usedStorageVal) {
          console.log('this.note', this.fileName)
          // let Temp = this.fileName.length + event.target.files.length
          this.showErr = ''
          for (let i = 0; i < event.target.files.length; i++) {
            let Isvalid = true
            let file = event.target.files[i]
            let imageSize = this.ImageSizePermission.v === undefined || this.ImageSizePermission.v === 0 ? 1048576 : this.ImageSizePermission.v * 1048576
            let videoSize = this.VideoSizePermission.v === undefined || this.VideoSizePermission.v === 0 ? 5242880 : this.VideoSizePermission.v * 1048576
            console.log('file.type', file.type)
            if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/svg' || file.type === 'application/pdf') {
                if (this.ImageCount < this.canMultipleImagePermission.v) {
                  console.log('file.size', file.size)
                  console.log('file.size', imageSize)
                  if (file.size < imageSize) {
                    this.ImageCount += 1
                    Isvalid = true
                    this.files.push(event.target.files[i])
                  } else {
                    Isvalid = false
                    this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size, You have only allowed to upload ${this.convertToMb(imageSize)}MB size image`, color: 'error'})
                    // this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size`, color: 'error'})
                  }
                } else {
                  Isvalid = false
                  this.showErr = `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} image attachment`
                  this.$store.dispatch('SetAlert', {showAlert: true, message: `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} image attachment`, color: 'error'})
                }
              } else {
                if (this.VideoCount < this.canMultipleVideoPermission.v) {
                  console.log('file.size', file.size)
                  console.log('file.size', videoSize)
                  if (file.size < videoSize) {
                    Isvalid = true
                    this.VideoCount += 1
                    this.files.push(event.target.files[i])
                  } else {
                    Isvalid = false
                    this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size, You have only allowed to upload ${this.convertToMb(videoSize)}MB size video`, color: 'error'})
                    // this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size`, color: 'error'})
                  }
                } else {
                  Isvalid = false
                  this.showErr = `You Don't Have permission to upload more than ${this.canMultipleVideoPermission.v} video attachment`
                  this.$store.dispatch('SetAlert', {showAlert: true, message: `You Don't Have permission to upload more than ${this.canMultipleVideoPermission.v} video attachment`, color: 'error'})
                }
              }
              if (Isvalid === true) {
                let newDate = new Date().getTime()
                let obj = {
                  file: file,
                  id: newDate,
                  uploadedFile: true,
                  attachedDocument: false,
                  documentName: '',
                  documentNotes: '',
                  progressLinearValue: 0,
                  source: ''
                }
                this.fileName.push(obj)
              }
            }
            console.log('this.ImageCount', this.ImageCount, this.VideoCount)
          // } else {
          //   this.showErr = `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} attachment`
          //   this.$store.dispatch('SetAlert', {showAlert: true, message: `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} attachment`, color: 'error'})
          // }
        } else {
          this.$store.dispatch('SetAlert', {showAlert: true, message: `You have only ${mb} MB storage and you already used total ${this.usedStorageVal} MB, please remove extra attachments`, color: 'error'})
        }

      }
    },
    getFileSize (bytes) {
      if (bytes === 0) return '0 Bytes'
      var decimals = 2
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    convertToMb (kb) {
      return kb / (1024 * 1024);
    },
    saveNote () {
      console.log('inside save', this.notesCategoriesObj.selectedCategoryId);
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      if (this.noteId > 0) {
      console.log('inside if', this.notesCategoriesObj.selectedCategoryId,this.noteId);
        MyJobApp.updateNoteWithAttach(
            this.files,
            this.requessType,
            this.noteId,
            this.reqestId,
            this.note,
            this.deleteAttach,
            this.visibleToCustomer,
            this.notesCategoriesObj.selectedCategoryId,            
            response => {
            document.body.style = 'overflow: visible;'
            this.$root.$emit('addNoteHandler', true)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        )
      } else {
      console.log('inside else', this.notesCategoriesObj.selectedCategoryId,this.noteId);
        MyJobApp.addNoteWithAttachment(
          this.files,
          this.requessType,
          this.reqestId,
          this.note,
          this.visibleToCustomer,
          this.notesCategoriesObj.selectedCategoryId,
          response => {
          document.body.style = 'overflow: visible;'
          this.$root.$emit('addNoteHandler', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      )
      }
    },
    getStorageInfo () {
      MyJobApp.GetUsedStorageDetail(
        response => {
          let fileSizeMB = response.Data.usedStorage / (1024 ** 2)
          this.usedStorageVal = fileSizeMB.toFixed(2)
          console.log('usedStorageVal', this.usedStorageVal)
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addNoteHandler')
    },
  },
  beforeDestroy () {}
}
</script>
<style scoped>
.zIndex {
  z-index: 100 !important;
}
</style>
