<template>
  <div class="">
      <div class="mt-8">
        <div class="flex justify-between flex-wrap mb-4">
           <div v-if="showfilters" class="flex flex-wrap">
            <div class="" v-for="(status, index) in entStatusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="entStatusList.length === index + 1"/>     
            </div>
           </div>
          <div class="flex">
            <div class="" v-for="(status, index) in statusList" :key="index" >
                <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleStatus(index)" :selected="status.selected" :btnText="status.statusName" :lastInlist="statusList.length === index + 1"/>     
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4 ml-1">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Job...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewjob(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create Job'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class=" pb-5">
          <div class="text-text1  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="job_number_col text-text1 w-14  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="jobTitle_col text-text1 w-72 flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''" class="bg-gray">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div v-if="showcustomer" class="company_name_col text-text1 w-56 flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <!-- <div class="estimation_col uppercase w-56 flex items-center">
              <span class="">{{titleObj[7].title}}</span>
            </div> -->
            <!-- <div class="type_col uppercase w-56 flex items-center">
              <span class="">{{titleObj[5].title}}</span>
            </div> -->
            <div class="viDate_col text-text1 w-56 flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="status_col text-text1 w-56 flex items-center" :class="titleObj[6].icon !== '' ? 'text-primary' : ''">
              <span class="">{{titleObj[6].title}}</span>
            </div>
            <div class="location_col text-text1 w-56 flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''">
              <span class="">{{titleObj[4].title}}</span>
            </div>
                      
          </div>
          <div v-if="jobList.length > 0">
            <div v-for="(data, index) in jobList" :key="index" @click="showJobDetail(data.jobId)" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer text-text1">
              <div class="job_number_col w-14">
                <div class="flex items-center">
                <span class="   heading-6"># {{data.jobNumber}}</span>
                </div>
              </div>
              <div class="jobTitle_col wpack_name">
                <div style="max-width: 420px; width: fit-content;" class=" truncate heading-4  text-text2 font-medium wpack_title">
                   <div class="  text-text2 font-bold heading-6" style="">
                    <p class=" font-normal heading-7"> 
                      <span class="text-primary" v-if="data.jobTypeName !== ''">({{data.jobTypeName}})</span>
                      <span v-if="data.jobEstMinutes > 0"> Est:{{setDisplayEstimation(data.jobEstMinutes)}}</span>
                    </p>
                    <p>{{data.jobTitle === '' ? '-' : data.jobTitle}}</p>
                   </div>
                  <span v-if="data.jobTitle.length > 57" class="tooltiptext whitespace-pre-line text-white bg-primary rounded  px-2 py-1 heading-4">{{ data.jobTitle }}</span>
                </div>
              </div>
              <div v-if="showcustomer" class="company_name_col w-56">
                <div class="flex items-center">
                <span class="   heading-6">{{data.companyName === '' ? '-' : data.companyName}}</span>
                </div>
              </div>
              <div class="viDate_col w-56">
                <div class="flex items-center">
                <span class=" heading-6" v-if="data.nextVisitDateTime !== ''">{{data.nextVisitDateTime | formatDataeTime}}</span>
                <span class=" heading-6" v-else>-</span>
                </div>
              </div>
              <div class="status_col w-56">
                <div class="flex items-center">
                  <div class=" heading-7 flex items-center">
                    
                    <span v-if="data.dateStatus === 'Unschedule'" class="text-warning h-5 w-5 pl-1"><i class="fas fa-circle"></i></span>
                    <span v-if="data.dateStatus === 'Late'" class="text-error h-5 w-5 pl-1"><i class="fas fa-circle"></i></span>
                    <span v-if="data.dateStatus === 'Today'" class="text-secondary h-5 w-5 pl-1"><i class="fas fa-circle"></i></span>
                    <p v-if="data.dateStatus === 'Upcoming'" class="text-primary h-5 w-5 pl-1"><i class="fas fa-circle"></i></p>
                    {{data.dateStatus}}
                  </div>
                </div>
              </div>
              <div class="location_col w-56">
                <div class="flex items-center">
                <span class="heading-6" v-if="data.addressLine1 !== ''">{{data.addressLine1}} {{data.city !== '' ? ',' : ''}} {{data.city}} </span>
                <span class=" heading-6" v-else>At Office</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
            :totalDataCount="totalJobCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
var moment = require('moment')
export default {
  name: "lead",
  components: {
    StatusBtn,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: '#', icon: '', sortName: 'jobNumber'},
        {title: 'Job Title', icon: '', sortName: 'jobTitle'},
        {title: 'Customer Name', icon: '', sortName: 'companyName'},
        {title: 'Next Visit Date', icon: 'DESC', sortName: 'nextVisitDateTime'},
        {title: 'Location', icon: '', sortName: 'addressLine1'},
        {title: 'Type', icon: '', sortName: 'jobTypeName'},
        {title: 'Status', icon: '', sortName: 'status'},
        {title: 'Estimation', icon: '', sortName: 'jobEstMinutes'},
        {title: 'City', icon: '', sortName: 'city'},
      ],
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'Action Required',
          id: 'actionRequired',
          selected: false,
        },
        {
          vistStatusId: 2,
          displayText: 'Upcoming',
          id: 'upcoming',
          selected: false,
        },
        {
          vistStatusId: 3,
          displayText: 'Today',
          id: 'today',
          selected: false,
        },
        {
          vistStatusId: 4,
          displayText: 'Late',
          id: 'late',
          selected: false,
        },
        {
          vistStatusId: 6,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
        {
          vistStatusId: 8,
          displayText: 'Unscheduled',
          id: 'unschedule',
          selected: false,
        },
      ],
      statusList: [{
        jobStatusId: 1,
        statusName: 'Open',
        selected: true,
      },
      {
        jobStatusId: 2,
        statusName: 'Working',
        selected: true,
      },
      {
        jobStatusId: 3,
        statusName: 'Closed',
        selected: true,
      }],
      jobList: [],
      searchForJob: '',
      getJobDebounce: null,
      resetPaginationValues: false,
      totalJobCount: 0,
      customerDetailId: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        entStatusList: [],
        sortBy: 'nextVisitDateTime',
        statusList: []
      },
    };
  },
  props: ["customerId", 'showfilters', 'showcustomer'],
  created() {},
  mounted() {
    if (this.customerId > 0) {
        this.customerDetailId = this.customerId
    }
    this.getJobsList()
     this.getJobDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
  },
  methods: {
    setDisplayEstimation (value) {
      let estimation = value
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let min = estimation % 60; // Get the remaining minutes
      let minutes = Math.round(min); // Get the remaining minutes
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + ' hr' : ''} ${hours > 0 && minutes > 0 ? "," : ''}` + `${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} mins` : ''}`;

      return formattedTime
    },
     toggleActiveAndInactiveA (id, dataVal) {
      this.entStatusList.map((data, i) => {
          if (dataVal.id === data.id) {
            if (this.entStatusList[i].selected) {
               this.filterObject.entStatusList = []
               this.entStatusList[i].selected = false
            } else {
                this.filterObject.entStatusList = data.id
               this.entStatusList[i].selected = true
            }
          } else {
            this.entStatusList[i].selected = false
          }
      })
      this.resetPaginationValues = true
      // this.getVisitList()
    },
    addNewjob () {
      this.$router.push({name: 'addNewJob', query: {customerId: this.customerDetailId}})
    },
    showJobDetail (id) {
      this.$router.push({name: 'jobDetail', params: {jobId: id}})
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getJobsList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getJobsList(true)
    },
    searchCall (data) {
      this.searchForJob = data
      this.getJobDebounce()
    },
    toggleStatus (index) {
      this.statusList[index].selected = !this.statusList[index].selected
      this.resetPaginationValues = true
      // this.getJobsList()
    },
    getJobsList (showlistLoader) {
      if (showlistLoader && this.searchForJob !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForJob === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.filterObject.statusList = []
      this.statusList.map((data) => {
      if (data.selected) {
        this.filterObject.statusList.push(data.jobStatusId)
      }
    })
      MyJobApp.getJobList(
         this.filterObject.start,
          this.filterObject.length,
          this.searchForJob,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          this.filterObject.entStatusList,
          this.customerDetailId,
          false,
          this.filterObject.statusList,
          '',
          response => {
            this.totalJobCount = response.Data.count
            let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
            for (let index = 0; index < temp.length; index++) {
              let currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              currentDate = new Date(currentDate);
              const cFormated = moment(currentDate).format('YYYY-MM-DD');
              if (temp[index].isUnschedule) {
                temp[index].dateStatus = 'Unschedule';
              } else {
                if (temp[index].nextVisitDateTime === '') {
                  temp[index].dateStatus = 'Unschedule';
                } else if ( moment(new Date(temp[index].nextVisitDateTime)).format('YYYY-MM-DD',) === cFormated) {
                  temp[index].dateStatus = 'Today';
                } else if ( new Date(temp[index].nextVisitDateTime).getTime() < currentDate.getTime()) {
                  temp[index].dateStatus = 'Late';
                } else {
                  temp[index].dateStatus = 'Upcoming';
                }
              }
            }
            this.jobList = temp
            this.resetPaginationValues = false
            this.$emit('jobcount', this.totalJobCount)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
.job_number_col {
  min-width: 60px !important;
  width: 60px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 10px;
}
.jobTitle_col {
  min-width: 450px !important;
  /* width: 280px !important; */
  /* overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
  position: relative;
  display: inline-block; */
}
.company_name_col {
  min-width: 380px !important;
  width: 380px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.estimation_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.type_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.viDate_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.location_col {
  min-width: 500px !important;
  width: 500px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.status_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}

.wpack_name {
  position: relative;
  display: inline-block;
}

.wpack_name .tooltiptext {
  visibility: hidden;
  text-align: center;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -20px;
  left: 50px;
  margin-left: -60px;
}
.wpack_title:hover .tooltiptext {
  visibility: visible !important;
}
</style>