<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Visits ({{totalQuoteCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex left-0 overflow-auto mb-2 items-center">
          <div class="" v-for="(status, index) in entStatusList" :key="index" >
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="entStatusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex  overflow-auto mb-2 items-center">
           <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn v-if="activeUer !== status.userDetailId" class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
            <StatusBtn v-else class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="'Me'" :lastInlist="empStatusList.length === index + 1"/>     
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-text1  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class=" title_col text-text1 w-14  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[1].title}}</span>
            </div>
            <div class=" customer_name_col text-text1 w-14  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[2].title}}</span>
            </div>
            <div class="start_date_col text-text1 w-14  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[3].title}}</span>
            </div>
            <div class=" spend_Time_col text-text1 w-14  flex items-center" :class="titleObj[6].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[6].title}}</span>
            </div>
            <div class=" assignee_col text-text1 w-14  flex items-center" :class="titleObj[5].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[5].title}}</span>
            </div>
            <div class=" assignee_col text-text1 w-14  flex items-center" :class="titleObj[8].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[8].title}}</span>
            </div>
            <div class=" statusCol text-text1 w-14  flex items-center" :class="titleObj[7].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" >{{titleObj[7].title}}</span>
            </div>
            </div>
            <div class="" v-if="visitLIst.length > 0">
              <div v-for="(data, index) in visitLIst" :key="index"  @click="showVisitDetail(data)" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer text-text1">
              <div class="title_col wpack_name">
                <p style="max-width: 380px; width: fit-content;" class=" truncate heading-4  text-text2 font-medium wpack_title">
                   <span class="  text-text2 font-bold heading-6" style="">{{data.jobTitle === '' ? '-' : data.jobTitle}}</span>
                  <span v-if="data.jobTitle.length > 57" class="tooltiptext whitespace-pre-line text-white bg-primary rounded  px-2 py-1 heading-4">{{ data.jobTitle }}</span>
                </p>
              </div>            
              <div class="customer_name_col w-14">
                <div class="flex items-center">
                <span class="   heading-6">{{data.companyName}}</span>
                </div>
              </div>              
              <div class="start_date_col w-14">
                <div class="flex items-center">
                <span class="   heading-6">
                  {{ data.visitStartDateTime  | dateFilterSchedule}}

                  ({{ data.visitStartDateTime  | timeFilterSchedule}} - {{ data.visitEndDateTime  | timeFilterSchedule}})
                </span>
                </div>
              </div>                   
              <div class="spend_Time_col w-14">
                <div class="flex items-center">
                <span v-if="data.totalSpendTime !== ''" class="   heading-6">{{data.totalSpendTime}}
                </span>
                <span v-else class="   heading-6">-</span>
                </div>
              </div>                   
              <div class="assignee_col w-14">
                <div class="flex items-center">
                  <div class="flex flex-wrap cursor-pointer">
                    <div class="" v-for="(user, index) in data.visitAssignee" :key="index">
                      <Chip v-if="activeUer !== user.userDetailId" :chipText="`${user.firstName}  ${user.lastName}`"/>
                      <Chip v-else :chipText="`Me`"/>
                    </div> 
                  </div>
                </div>
              </div>              
              <div class="assignee_col w-14">
                <div class="flex items-center">
                  <span class="   heading-6" v-if="data.addressLine1 !== ''">{{data.addressLine1}} {{data.city !== '' ? ',' : ''}} {{data.city}} </span>
                <span class="   heading-6" v-else>At Office</span>
                </div>
              </div>              
              <div class="statusCol w-14">
                <div class="flex items-center">
                <div class=" heading-5 flex items-center" v-if="!data.isCompleted">
                  <span v-if="data.dateStatus === 'Late'" class="heading-7 text-error h-5 w-5 pl-1"><i class="fas fa-circle"></i></span>
                    <span v-if="data.dateStatus === 'Today'" class="heading-7 text-secondary h-5 w-5 pl-1"><i class="fas fa-circle"></i></span>
                    <p v-if="data.dateStatus === 'Upcoming'" class="heading-7 text-primary h-5 w-5 pl-1"><i class="fas fa-circle"></i></p>
                  {{data.dateStatus}}
                </div>
                <div class=" heading-5 flex items-center" v-else>
                  <p class="heading-7 text-success h-5 w-5 pl-1"><i class="fas fa-circle"></i></p>
                  Completed
                </div>

                </div>
              </div>              
            </div>
            </div>
            <div v-else>
              <NoRecords :alertMessage="`No records found.`" />
            </div>
            <div class=" mt-4">
              <Pagination
                :totalDataCount="totalQuoteCount"
                :resetPagination="resetPaginationValues"
                @callApiAsPerPagination="getLeadDataFromPagination"/>
            </div>
        </div>
      </div>
     </div>
   </div>
</template>
<script>
import {getCookies} from '@/utils/cookies'
import Cookies from 'vue-cookies'
var moment = require('moment')
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
// import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import {getDisplayText} from '@/utils/convertDateAndTime.js'
import Chip from '../components/chip.vue'
export default {
  name: "activity",
  components: {
    NoRecords,
    StatusBtn,
    Pagination,
    Chip,
    // Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
       titleObj: [
        {title: 'Id', icon: '', sortName: 'visitId'},
        {title: 'Job Title', icon: '', sortName: 'jobTitle'},
        {title: 'Customer Name', icon: '', sortName: 'companyName'},
        {title: 'Schedule Time', icon: '', sortName: 'visitStartDateTime'},
        {title: 'End Date', icon: '', sortName: 'visitEndDateTime'},
        {title: 'Assignee', icon: '', sortName: 'assignees'},
        {title: 'Spend Time', icon: '', sortName: 'assignees'},
        {title: 'Status', icon: '', sortName: ''},
        {title: 'Location', icon: '', sortName: 'addresline1'},
      ],
      activeUer: 0,
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'Action Required',
          id: 'actionRequired',
          selected: false,
        },
        {
          vistStatusId: 2,
          displayText: 'Upcoming',
          id: 'upcoming',
          selected: false,
        },
        {
          vistStatusId: 3,
          displayText: 'Today',
          id: 'today',
          selected: false,
        },
        {
          vistStatusId: 4,
          displayText: 'Late',
          id: 'late',
          selected: false,
        },
        {
          vistStatusId: 5,
          displayText: 'Running',
          id: 'running',
          selected: false,
        },
        {
          vistStatusId: 6,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
        {
          vistStatusId: 7,
          displayText: 'Not Assigned',
          id: 'notAssignee',
          selected: false,
        },
      ],
      empStatusList: [],
      visitLIst: [],
      searchForQuotation: '',
      getQuotationDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalQuoteCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        sortBy: 'addedDate',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  created() {},
  mounted() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
     this.getQuotationDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      if (response) {
        this.getVisitList(false, false)
      }
      this.addLead = false
    });
    this.getEmployeeList()
    this.getVisitList(false, false)
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getJobsList(true)
    },
    toggleActiveAndInactiveA (id, dataVal) {
        this.entStatusList.map((data, i) => {
            if (dataVal.id === data.id) {
              if (this.entStatusList[i].selected) {
                 this.filterObject.entStatusList = []
                 this.entStatusList[i].selected = false
              } else {
                  this.filterObject.entStatusList = data.id
                 this.entStatusList[i].selected = true
              }
            } else {
              this.entStatusList[i].selected = false
            }
        })
        this.getVisitList(false, true)
    },
    toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.empStatusList = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.empStatusList.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.empStatusList.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.getVisitList(false, true)
      var date = new Date();
      date.setTime(date.getTime()+(2*60*60*1000));
      var expires = "; expires="+date.toGMTString();
      Cookies.set('visitFilter', this.filterObject.empStatusList, expires)
      console.log('filter', filter)
    },
    toggleActiveAndInactiveC () {
      this.filterObject.empStatusList = []
      this.empStatusList.forEach((a) => {
        if (a.selected) {
          this.filterObject.empStatusList.push(a.userDetailId)
        }
      })
      this.getVisitList(false, true)
    },
    showVisitDetail (data) {
      this.$router.push({name: 'visitDetail', params: {visitId: data.visitId}})
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true, false)
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          const findex = temp.findIndex(x => x.userDetailId === this.activeUer)
          if (findex > -1) {
            let element = temp[findex];
            temp.splice(findex, 1);
            temp = [element, ...temp];
          }
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = false
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "All",
            selected: false
          }
          this.empStatusList = [newFirstElement].concat(temp)
          let tempA = getCookies('visitFilter')
          console.log('visitFilter', tempA)
          if (tempA !== null) {
            const array = tempA.split(',');
            for (let II = 0; II < this.empStatusList.length; II++) {
              for (let JJ = 0; JJ < array.length; JJ++) {
                console.log('fasasdadd', JSON.parse(array[JJ]))
                if (this.empStatusList[II].userDetailId === JSON.parse(array[JJ])) {
                  this.empStatusList[II].selected = true
                }
              }
            }
            this.toggleActiveAndInactiveC()
          } else{
            for (let index = 0; index < temp.length; index++) {
              temp[index].selected = true
              temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
            }
            const newFirstElement = {
              userDetailId: 0,
              fullName: "All",
              selected: true
            }
            this.empStatusList = [newFirstElement].concat(temp)
          }
          console.log('this.empStatusList', this.empStatusList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getVisitList (showlistLoader, reset) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (reset) {
        this.resetPaginationValues = true
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getvisitLIst(
        0,
        this.filterObject.entStatusList,
        this.filterObject.start,
        this.filterObject.length,
        this.filterObject.empStatusList,
        response => {
          this.totalQuoteCount = response.count
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].visitEndDateTime !== '' && temp[index].visitStartDateTime !== '') {
              temp[index].displayText = getDisplayText(temp[index].visitStartDateTime, temp[index].visitEndDateTime)
            } else {
             temp[index].displayText = '--'
            }
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            currentDate = new Date(currentDate);
            const cFormated = moment(currentDate).format('YYYY-MM-DD');
            if ( moment(new Date(temp[index].visitStartDateTime)).format('YYYY-MM-DD',) === cFormated) {
              temp[index].dateStatus = 'Today';
            } else if ( new Date(temp[index].visitStartDateTime).getTime() < currentDate.getTime()) {
              temp[index].dateStatus = 'Late';
            } else {
              temp[index].dateStatus = 'Upcoming';
            }
          }
          this.visitLIst = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    setDisplayEstimation (value) {
      if (value > 0) {
          let estimation = value
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let min = estimation % 60; // Get the remaining minutes
      let minutes = Math.round(min); // Get the remaining minutes
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + 'h' : ''} ${hours > 0 && minutes > 0 ? ":" : ''}`+`${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} m` : ''}`;

      return formattedTime
      } else {
        return '0'
      }
      
    },
  }
};
</script>
<style scoped>
.id_col {
  min-width: 60px !important;
  width: 60px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.title_col {
  min-width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.customer_name_col {
  min-width: 250px !important;
  width: 250px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.start_date_col {
  min-width: 290px !important;
  width: 290px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.end_date_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.assignee_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.statusCol {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}
.spend_Time_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 16px;
}

.wpack_name {
  position: relative;
  display: inline-block;
}

.wpack_name .tooltiptext {
  visibility: hidden;
  text-align: center;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -20px;
  left: 50px;
  margin-left: -60px;
}
.wpack_title:hover .tooltiptext {
  visibility: visible !important;
}
</style>